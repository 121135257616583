import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import HomePage from './HomePage';
import Header from './Header';
import Footer from './Footer';
import ShopPage from './pages/ShopPage';
import AboutPage from './pages/AboutPage';

function App() {
  return (
    <Router>
      <div className="App" style={{ overflow: 'hidden' }}> {/* Add inline style to prevent scrolling */}
        <Header />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/ShopPage" element={<ShopPage />} />
          <Route path="/AboutPage" element={<AboutPage />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
