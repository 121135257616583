import React from 'react';
import { Link } from 'react-router-dom';

import './Header.css';

function Header() {
  return (
    <nav className="Header">
      <div className="logo">
        <Link to="/"> {/* Link to the homepage */}
          <img src={`${process.env.PUBLIC_URL}/guy-transparent.png`} alt="Logo" />
        </Link>
      </div>
      <ul>
        <li>
          <Link to="/ShopPage">Shop</Link>
        </li>
        <li>
          <Link to="/AboutPage">About</Link>
        </li>
      </ul>
    </nav>
  );
}

export default Header;
