import './AboutPage.css';

function AboutPage() {
  return (
    <div className="AboutPageBackground">
      <h1>New Drops Coming Soon</h1>
    </div>
  );
}

export default AboutPage;
