import React, { useState, useEffect } from 'react';
import './HomePage.css';

function HomePage() {
  const [backgroundPosition, setBackgroundPosition] = useState('center calc(50% - 80px)');

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 768) {
        setBackgroundPosition('left -200px center');
      } else {
        setBackgroundPosition('center calc(50% - 80px)');
      }
    }

    handleResize(); // Call handleResize initially to set the correct background position
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const backgroundStyle = {
    backgroundImage: `url(${process.env.PUBLIC_URL}/Toby.jpg)`,
    backgroundSize: 'cover',
    backgroundPosition,
    overflow: 'hidden',
    pointerEvents: 'none', // Disable mouse events for the background image
  };

  return (
    <div className="HomePageBackground" style={backgroundStyle}>
      <h1>New Drops Coming Soon</h1>
    </div>
  );
}

export default HomePage;
