import React, { useState, useEffect } from 'react';
import './ShopPage.css';

function ShopPage() {
  const [backgroundPosition, setBackgroundPosition] = useState('center calc(50% - 80px)');

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 768) {
        setBackgroundPosition('center calc(50% - -50px)');
      } else {
        setBackgroundPosition('center calc(50% - 0px)');
      }
    }

    handleResize(); // Call handleResize initially to set the correct background position

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const backgroundStyle = {
    backgroundImage: `url(${process.env.PUBLIC_URL}/guywarbw.png)`,
    backgroundSize: 'cover',
    backgroundPosition,
    overflow: 'hidden',
  };

  return (
    <div className="ShopPageBackground" style={backgroundStyle}>
      <h1>New Drops Coming Soon</h1>
    </div>
  );
}

export default ShopPage;
